.cardText {
  margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
}

.cardImage {
  margin-top: 20px;
	margin-bottom: 20px;
	width: 50%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.cardContent {
	width: 85%;
	min-height: 160px;
}

.cardOpenContent {
	position: relative;
	min-height: 400px;
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
  width: 100%;
  height: 100%;	
}

.contentBox {
	display: block;
	/* word-break: break-all; */
	overflow-wrap: break-word;
	font-size: 16pt;
	margin-top: 20px;
	padding-bottom: 25px;
}

.contentBoxOpen {
	display: block;
	overflow-wrap: break-word;
	width: 85%;
	font-size: 22pt;
	margin-top: 20px;
	padding-bottom: 25px;
}

.buttonBox {
	display: block;
	position: absolute;
	padding-top: 20px;
	padding-right: 5%;
	top: 0;
	right: 0;
}

.buttonBoxOpen {
	display: block;
	position: absolute;
	padding-top: 0px;
	padding-right: 5%;
	top: 0;
	right: 0;
}

.votesContainer {
	text-align: center;
}

.postTitle {
	word-break: break-all;
	margin-top: -5px;
	font-size: 18pt;
}

.postTitleOpen {
	word-break: break-all;
	margin-top: -5px;
	width: 85%;
	font-size: 26pt;
}

.postImage {
	width: 100%;
}

.postImageOpen {
	width: 100%;
}

.postFooter {
  position: absolute;
	padding-bottom: 5px;
	bottom: 0px;
}

.postFooterOpen {
	position: absolute;
	padding-bottom: 0px;
	bottom: -15px;
}
