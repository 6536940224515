.feed {
	margin: auto;
  max-width: 70%;
}

.postButtonBox {
	display: block;
	width: 100px;
	padding-top: 20px;
	margin-left: auto;
	margin-right: auto;
}

.spinnerBox {
	position: absolute;
	top: 50%;
	left: 50%; 
	margin-left: -25px;
	margin-top: -25px;
}

